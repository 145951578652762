import useExitIntent from "components/ExitIntent/useExitIntent"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 14px;
  overflow: hidden;
  padding: 0 25px;
  display: flex;
  align-items: center;
  .bg {
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    > :first-child {
      height: 100%;
      width: 100%;
    }
  }

  @media (max-width: 660px) {
    flex-direction: column;
    text-align: center;
    > :nth-child(2) {
      max-width: 150px;
      margin-top: 10px;
    }
  }
`

const Content = styled.div`
  z-index: 0;
  min-width: 400px;
  padding-left: 50px;
  padding: 15px 20px 20px 50px;
  h3 {
    font-size: 24px;
    font-weight: bold;
    font-family: "Rammetto One";
  }

  @media (max-width: 660px) {
    padding: 5px 20px 10px 20px;
    h3 {
      margin-top: 5px;
    }
  }
`

const FreeResourcesAdvert = () => {
  const { component, display } = useExitIntent(false)
  const { banner, bundle } = useStaticQuery(graphql`
    {
      banner: file(relativePath: { eq: "purple-banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1280)
        }
      }
      bundle: file(relativePath: { eq: "resources-bundle.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 320)
        }
      }
    }
  `)

  return (
    <Wrapper>
      {component}
      <div className="bg">
        <GatsbyImage image={getGatsbyImage(banner)} alt="Banner" />
      </div>
      <GatsbyImage image={getGatsbyImage(bundle)} alt="Resources Bundle" />
      <Content>
        <h3>Get Free Printable Resources</h3>
        <p>
          Daily activities, ready-to-go lessons with plans, answers and much
          more!
        </p>
        <Button theme="primary" size="small" onClick={() => display(true)}>
          Get Free Resources
        </Button>
      </Content>
    </Wrapper>
  )
}

export default FreeResourcesAdvert
