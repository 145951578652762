import React from 'react'
import styled from 'styled-components'
import FreeResourcesAdvert from 'components/Adverts/FreeResourcesAdvert'
import PostCard, { IPostCard } from '../PostCard'

const Wrapper = styled.div`
  .advert {
    margin: 25px 0px;
  }
  @media (max-width: 1120px) {
    .advert {
      padding: 0 20px;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat( auto-fill, minmax(300px, 1fr) );
  @media (max-width: 1120px) {
    padding: 0 20px;
  }
`

interface IProps {
  posts: IPostCard[]
}

const PostsGrid = (props: IProps) => {
  const groups = props.posts.reduce((acc: IPostCard[][], post: IPostCard, i: number) => {
    if (i % 6 === 0) {
      acc.push([post])
    } else {
      acc[acc.length - 1].push(post)
    }
    return acc
  }, [])

  return <Wrapper>
    { groups.map(posts => <>
        <Grid>
          { posts.map(p => <PostCard key={p.slug} {...p} />)  }
        </Grid> 
        <div className='advert'>
          <FreeResourcesAdvert />
        </div>
      </>
    )}
  </Wrapper>
}

export default PostsGrid
