import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import SEO from 'components/SEO'
import BlogHero from "./components/BlogHero"
import { IPostCard } from "./components/PostCard"
import PostsGrid from "./components/PostsGrid"

const Wrapper = styled.div`
  background-image: linear-gradient(90deg, #572779, #45106B);
  color: rgb(70, 70, 70);
  padding-top: 50px;
  padding-bottom: 50px;
  color: #fff;
`

const Content = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`

const Header = styled.div`
  > :first-child {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    line-height: 2.3;
    font-size: 1.1em;
    padding: 0 20px;
    margin: 0 auto;
  }

  margin-bottom: 15px;
  background-image: linear-gradient(90deg, #6a3f88, #572779);
`

const Category = styled.div`
  position: relative;
  padding: 5px 20px;
  font-weight: 700;

  ::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
    ${({ selected }: { selected: boolean }) =>
      selected &&
      css`
        border-bottom: 1px solid #fff;
      `}
  }
`

interface IProps {
  categorySlug?: string
  posts: IPostCard[]
  categories: { slug: string; title: string }[]
}

const BlogPageTemplate = (props: IProps) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }`
  )

  const category = props.categories.find(c => c.slug === props.categorySlug)

  const SEOProps = {
    title: category?.title || `Blog`,
    titleTemplate: "%s | Night Zookeeper Blog",
    image: site.siteMetadata.image,
    url: category ? `${site.siteMetadata.siteUrl}/blog/categories/${category.slug}` : `${site.siteMetadata.siteUrl}/blog`,
    canonical: `${site.siteMetadata.siteUrl}/blog`,
    description: site.siteMetadata.description,
  }

  return <>
    <Wrapper>
      <BlogHero />
      <Header>
        <div>
          <Link to='/blog'>
            <Category selected={!category?.slug}>
              All
            </Category>
          </Link>
          {props.categories.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true })).map(c => (
            <Link key={c.slug} to={`/blog/categories/${c.slug}`}>
              <Category selected={c.slug === category?.slug}>
                {c.title}
              </Category>
            </Link>
          ))}
        </div>
      </Header>
      <Content>
        <PostsGrid posts={props.posts} />
      </Content>
    </Wrapper>
    <SEO {...SEOProps} />
  </>
}

BlogPageTemplate.defaultProps = {
  categorySlug: null,
}

export default BlogPageTemplate
