import { Link } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React, { useMemo } from "react"
import Moment from "react-moment"
import styled from "styled-components"

const Wrapper = styled.div`
  max-width: 100%;
`

const Title = styled.h3`
  margin: 0;
  margin-bottom: 8px;
  font-family: 'Rammetto One';
  font-size: 1.4rem;
  line-height: 1.3;
`

const Thumbnail = styled.div`
  width: 100%;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  > :first-child {
    width: 100%;
  }
  img {
    width: 100%;
  }
  margin-bottom: 15px;
`

const Author = styled.div`
  display: flex;
  align-items: center;
  color: #f4cf5d;
  margin-bottom: 8px;
  font-size: 1.3rem;
  > :first-child {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 12px;
  }
`

const BlogDate = styled.div`
  margin-bottom: 8px;
`

const Excerpt = styled.div`
  font-size: 1rem;
  line-height: 1.3;
`

export interface IPostCard {
  title: string
  slug: string
  thumbnail: any
  mainThumbnail?: any
  excerpt: string
  originalPublishDate: number
  published_at?: number
  author: {
    fullName: string
    avatar: any
  }
}

const PostCard = (props: IPostCard) => {

  const img = useMemo(() => {
    if (props.mainThumbnail?.localFile?.childImageSharp) {
      return <GatsbyImage
        image={getImage(props.mainThumbnail?.localFile) as IGatsbyImageData}
        alt={`${props.title} thumbnail`}
      />
    }
    if (props.thumbnail?.localFile?.childImageSharp) {
      return <GatsbyImage
        image={getImage(props.thumbnail?.localFile) as IGatsbyImageData}
        alt={`${props.title} thumbnail`}
      />
    }
    return <img src={props.thumbnail?.url} alt={`${props.title} thumbnail`} />
  }, [])

  return <Link to={`/blog/articles/${props.slug}`}>
    <Wrapper>
      <Thumbnail>
        { img }
      </Thumbnail>
      {props.author && (
        <Author>
          <GatsbyImage
            image={getImage(props.author.avatar.localFile) as IGatsbyImageData}
            alt={`${props.author.fullName} avatar`}
          />
          <div>{props.author.fullName}</div>
        </Author>
      )}
      {props.originalPublishDate && (
        <BlogDate>
          <Moment format="LL">{props.originalPublishDate}</Moment>
        </BlogDate>
      )}
      <Title>{props.title}</Title>
      <Excerpt>
        <div>{props.excerpt}</div>
      </Excerpt>
    </Wrapper>
  </Link>
}

export default PostCard
